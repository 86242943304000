<template>
  <div>
    <div class="inline">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thêm mới phiếu CSKH'">
          <template v-slot:preview>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <b-form-group
                id="input-group-1"
                class="col-3"
                style="padding: 0px"
              >
                <template>
                  <label>Doanh nghiệp:</label>
                </template>
                <b-form-select
                  class="mt-2"
                  size="sm"
                  id="input-4"
                  v-model="selectedCompany"
                  :options="listCompany"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">-- Chọn doanh nghiệp --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                class="col-3"
                style="padding: 0px"
              >
                <template>
                  <label>Kiểu tặng:</label>
                </template>
                <b-form-select
                  class="mt-2"
                  size="sm"
                  id="input-4"
                  v-model="selectedCareType"
                  :options="listType"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">-- Chọn kiểu tặng --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                class="col-3"
                style="padding: 0px"
              >
                <template>
                  <label>Lý do:</label>
                </template>
                <b-form-select
                  class="mt-2"
                  size="sm"
                  id="input-4"
                  v-model="selectedCareForm"
                  :options="listCareForm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">-- Chọn lý do --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                class="col-3"
                style="padding: 0px"
              >
                <template>
                  <label>Trị giá:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="input-4"
                  size="sm"
                  v-model="$v.form.value.$model"
                  :state="validateState('value')"
                  required
                  placeholder="Nhập trị giá"
                  class="mt-2 text-right"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.form.value.required">
                  Vui lòng nhập trị giá
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="!$v.form.value.between">
                  Vui lòng nhập trị giá từ {{$v.form.value.$params.between.min}} - {{$v.form.value.$params.between.max}}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-if="!$v.form.value.numeric">
                  Vui lòng không nhập chữ và ký tự đặc biệt
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-5"
                class="col-3"
                style="padding: 0px"
              >
                <label for="input-name">Ghi chú</label>
                <b-form-textarea
                  size="sm"
                  id="textarea1"
                  class="input-style"
                  v-model="note"
                  :placeholder="'Thêm ghi chú'"
                  :rows="6"
                  :max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                class="col-3"
                style="padding: 0px"
              >
                <label for="input-name">Khách hàng: <span class="text-danger">*</span></label>
                <Autosuggest
                  :model="$v.form.searchCustomerInfo.$model"
                  :suggestions="filteredOptionsCustomer"
                  placeholder="số điện thoại khách hàng"
                  :limit="10"
                  @select="onSelectedCustomer($event)"
                  @change="onInputChangCustomer"
                  suggestionName="suggestionName"
                  :state="validateState('searchCustomerInfo')"
                  errorMessages="Vui lòng nhập số điện thoại khách hàng"
                />
              </b-form-group>
            </v-form>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="addNewCustomerCare"
            >Lưu</b-button>
            <router-link :to="'/customer-care'">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
              >Hủy</b-button>
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { required, between, numeric } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { TIME_TRIGGER } from '@/utils/constants';
import decounce from 'debounce';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { makeToastSuccess, makeToastFaile } from '@/utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      listCity: [],
      listDistrict: [],
      isNew: true,
      form: {
        value: '',
        searchCustomerInfo: '',
      },
      inputSearchCustomerProp: {
        id: 'autosuggest__input',
        placeholder: 'SĐT Khách hàng',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      listCustomer: [],
      filteredOptionsCustomer: [],
      optionsCustomer: [],
      searchCustomer: '',
      selectedCareType: null,
      listType: [
        {
          id: 1,
          name: 'Tặng điểm',
        },
        {
          id: 2,
          name: 'Trừ điểm',
        },
      ],
      listCompany: [],
      selectedCompany: null,
      listCareForm: [],
      selectedCareForm: null,
      note: '',
      selectedCustomer: null,
    };
  },
  validations: {
    form: {
      value: { required, between: between(1, 1000), numeric },
      searchCustomerInfo: { required },
    },
  },
  components: {
    KTCodePreview,
    Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Chăm sóc khách hàng', route: '/customer-care' },
      { title: 'Danh sách phiếu CSKH', route: '/customer-care' },
      { title: 'Thêm mới phiếu CSKH' },
    ]);
  },
  created() {
    this.fetchCompany();
    this.getListCareForm();
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    addNewCustomerCare() {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const data = {
        point: this.$v.form.value.$model,
        customerCareForm: this.selectedCareForm,
        companyId: this.selectedCompany,
        careType: this.selectedCareType,
        customerId: this.selectedCustomer,
        description: this.note,
      };
      ApiService.post('customerCare', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.$router.push({
              path: '/customer-care',
            });
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
          }
        });
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
      });
    },
    getListCareForm() {
      this.listCareForm = [];
      ApiService.get(`customerCareForm/getAll`)
        .then((data) => {
          data.data.data.forEach((element) => {
            const item = {
              id: element.id,
              name: element.formName,
            };
            this.listCareForm.push(item);
          });
        })
        .catch(() => {});
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.suggestionName;
      this.selectedCustomer = option.item.id;
      this.$v.form.searchCustomerInfo.$model = option.item.suggestionName;
    },
    onInputChangCustomer(text = '') {
      this.searchCustomer = text;
      if (this.searchCustomer === '' || this.searchCustomer.length - 1) {
        this.$v.form.searchCustomerInfo.$model = '';
      }
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer];
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      this.debounceInput();
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    getListCustomer() {
      let text = this.searchCustomer;
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          const customers = data.data.data;
          const convertedCustomers = customers.map((customer) => ({
            ...customer,
            suggestionName: `${customer.phoneNo} ( ${customer.fullName} )`,
          }));
          this.optionsCustomer = [...convertedCustomers];
          this.filteredOptionsCustomer = [...convertedCustomers];
        },
      );
    },
  },
};
</script>
